/**
 * SEO / Page Helmet component
 */

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { GenerateMetaTags } from './meta-tags'

export type PageHelmetProps = {
  description?: string
  lang?: string
  meta?: []
  title: string
  pageUrl?: string
  socialImage?: string
}

export const PageHelmet = (props: PageHelmetProps) => {
  const queryResult = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  )

  const { site } = queryResult

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang,
      }}
      title={props.title || 'Maciej  Markowicz'}
    >
      {GenerateMetaTags(props, queryResult)}

      {/* <script
        src="https://www.paypal.com/sdk/js?client-id=AeYox8GQ2ltz_5YVdasU327rfDCsfnsDrJ00eJub_RJHEDZNcxtF4jBFlSzL1Opa7sCCpjIl6GK4oWEi&currency=EUR"
        data-sdk-integration-source="button-factory"
      ></script> */}
    </Helmet>
  )
}


