import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'

import {
  WrapperFlex,
  StyledHeaderOuter,
  StyledMenu,
  NavBox,
  MenuButton,
  AdditionalMobileItems,
  StyledNameText,
  StyledIcon,
  StyledNameLink,
} from './style'

import {linkResolver} from '@prismic/linkResolverHelper'
import useScrollingUp from '@hooks/scrollUp'
import { GatsbyImage } from 'gatsby-plugin-image'

type HeaderProps = {
  props: {
    activeMeta: any
    menu: any
  }
}

export const Header: React.FC<HeaderProps> = ({ props }) => {
  const { menu, activeMeta } = props
  const [state, setState] = useState({
    showMenu: false,
  })

  const isScrollingUp = useScrollingUp()

  const hideMenu = () => {
    if (!state.showMenu) {
      return
    }
    setState({
      showMenu: false,
    })
  }
  const toggleMenu = () => {
    setState({
      showMenu: !state.showMenu,
    })
  }

  const logo = props?.menu?.data?.logo[0]
  const logoImage = props?.menu?.data?.logo_image

  const renderedMenuLinks = menu?.data?.menu_links
    ? menu?.data?.menu_links.map((menuLink:any, index: number) => {
        const hasTarget = menuLink.link.target
        if (hasTarget) {
          return (
            <li key={`nav-${index}`}>
              <Link to={menuLink.link.url} target={hasTarget}>
                {menuLink.label.text}
              </Link>
            </li>
          )
        }
        const linkResolved = linkResolver(menuLink.link)
        if (menuLink.label.text === 'donate' || menuLink.label.text === 'Donate') {
                    return (
                      <li key={`nav-${index}`} className="link-for-donation">
                        <span onClick={hideMenu}>
                          <Link to={'#donate'}>{menuLink.label.text}</Link>
                        </span>
                      </li>
                    )
        }
          return (
            <li key={`nav-${index}`}>
              <Link to={linkResolved}>{menuLink.label.text}</Link>
            </li>
          )
      })
    : null

  return (
    <StyledHeaderOuter
      className={`${isScrollingUp ? 'sticky-header' : 'hide-header'}`}
    >
      <WrapperFlex justifyContent="space-between" alignItems="center">
        <Box>
          <StyledNameLink className="root-link" to={logo?.link.url}>
            {logoImage && (
              <GatsbyImage
                image={logoImage?.gatsbyImageData}
                alt="Moving Camera fundation logo"
              />
            )}
            {!logoImage && logo?.name.text}
          </StyledNameLink>
        </Box>
        <>
          <MenuButton as="button" onClick={toggleMenu}>
            {activeMeta.lang === 'en-us' ? 'Menu' : 'Menü'}
          </MenuButton>
          <NavBox as="nav" className={state.showMenu ? 'is-menu-open' : ''}>
            <div className="background"></div>
            <StyledMenu>
              {renderedMenuLinks}
              {/* <li>
                  <LangSwitcher props={activeMeta} />
                </li> */}
            </StyledMenu>
            <AdditionalMobileItems>
              <Flex justifyContent="space-between">
                <StyledNameText className="root-link">
                  {logoImage && (
                    <GatsbyImage
                      image={logoImage?.gatsbyImageData}
                      alt="Moving Camera fundation logo"
                    />
                  )}
                  {!logoImage && logo?.name.text}
                </StyledNameText>
                <StyledIcon onClick={toggleMenu}>&times;</StyledIcon>
              </Flex>
            </AdditionalMobileItems>
          </NavBox>
        </>
      </WrapperFlex>
    </StyledHeaderOuter>
  )
}


Header.displayName = 'Header'

export const query = graphql`
  fragment header on PrismicMenu {
    data {
      logo {
        link {
          url
        }
        name {
          text
        }
      }
      logo_image {
        alt
        copyright
        url
        gatsbyImageData(placeholder: NONE)
      }
      menu_links {
        link {
          uid
          type
          lang
          url
          target
        }
        label {
          text
        }
      }
    }
  }
`

