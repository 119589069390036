import React, { ReactNode } from 'react'
import { PageHelmetProps } from './page-helmet'

export function GenerateMetaTags(
  helmetProps: PageHelmetProps,
  query: any,
): ReactNode {
  const { title, description, socialImage, lang, meta, pageUrl } = helmetProps
  const metaDescription = description || query.site.siteMetadata.description

  // const imageUrl =
  //   lang === 'pl'
  //     ? query.image_pl.childImageSharp?.gatsbyImageData.src
  //     : query.image_en.childImageSharp?.gatsbyImageData.src


  const finalMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: `${query.site.siteMetadata.siteUrl}${pageUrl}`,
    },
    {
      property: 'og:locale',
      content: lang === 'pl' ? 'pl_PL' : 'en_US',
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `${
        socialImage ||
        'https://images.prismic.io/maciejmarkowiczwebsite/d8d6cbf0-1397-4542-8cb4-a0050acfba5f_Maciej_Markowicz_with_Motiongraph_108_at_French_Museum_of_Photography.jpg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50&rect=0%2C20%2C1067%2C560&w=1200&h=630&height=630&width=1200'
      }`,
    },
    {
      property: `og:site_name`,
      content: `Maciej Markowicz's Official Website`,
    },

    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:url`,
      content: `${query.site.siteMetadata.siteUrl}${pageUrl}`,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: `${
        socialImage ||
        'https://images.prismic.io/maciejmarkowiczwebsite/d8d6cbf0-1397-4542-8cb4-a0050acfba5f_Maciej_Markowicz_with_Motiongraph_108_at_French_Museum_of_Photography.jpg?ixlib=gatsbyFP&auto=compress%2Cformat&fit=max&q=50&rect=0%2C20%2C1067%2C560&w=1200&h=630&height=630&width=1200'
      }`,
    },
    {
      name: 'language',
      content: lang === 'pl' ? 'pl-PL' : 'en-US',
    },
    {
      name: 'url',
      content: `${query.site.siteMetadata.siteUrl}${pageUrl}`,
    },
    {
      name: 'robots',
      content: 'index,follow',
    },
    {
      name: 'google',
      content: 'notranslate',
    },
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
    {
      name: 'msapplication-tap-highlight',
      content: 'no',
    },
    {
      name: 'pinterest',
      content: 'nohover',
    },
    {
      name: 'msapplication-TileColor',
      content: '#da532c',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
    {
      name: 'referrer',
      content: 'no-referrer-when-downgrade',
    },
  ].concat(meta || [])

  const elementsToRender =
    finalMeta?.length &&
    finalMeta.map((element, index) => {
      if (element.property) {
        return (
          <meta
            key={`meta${index}`}
            property={element?.property}
            content={element?.content}
          />
        )
      } else {
        return (
          <meta
            key={`meta${index}`}
            name={element?.name}
            content={element?.content}
          />
        )
      }
    })

  return elementsToRender
}
