
import { LargeContainerBox } from '@components/container/container'
import styled from '@emotion/styled'
import {
  oWrapperOuter,
} from '@styles/05-objects/objects.wrapper'
import { rem } from '@styles/text'
import { Box, Flex } from '@rebass/grid'
import { bp, breakpoint } from '@styles/02-tools/tools.breakpoints'

export const StyledFooterOuter = styled.footer`
  .gatsby-image-wrapper-constrained {

    position: absolute;
    width: 100vw;
    min-height: 130vh;
;


  ${breakpoint.gt('md')`
    height: auto;
  `}



    &::before {
      content: '';
      position:absolute;
      width: 100vw;
      height: 100%;
      background-color: rgba(0,0,0,0.5);
      z-index: 2;
    }

  }
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
    position: relative;
        min-height: 100vh;


  ${breakpoint.gt('md')`
  height: 80vh;
  `}

  .copyright {
    position: absolute;
    bottom: 2rem;
    right: 5vw;

  }
`

export const StyledFooterForm = styled(Box)`
  #mc_embed_signup .form {
    max-width: 325px;
    padding: 0;
    text-align: right;
    margin-bottom: 1.5rem;

    ${breakpoint.gt('md')`
      margin-bottom: 2.5rem;
      text-align: left;
  `}
  }
`
export const StyledFooterFormTitle = styled.h2`
  font-size: ${rem(39)}rem;
    margin-bottom: 1.5rem;

    ${breakpoint.gt('md')`
    margin-bottom: 2.5rem;
  `}
`
export const StyledFooterSectionTitle = styled.h3`
  font-size: ${rem(39)}rem;
    margin-bottom: 1.5rem;

    ${breakpoint.gt('md')`
  text-align: right;
    margin-bottom: 2.5rem;
  `}
`

export const StyledFooterSectionLinks = styled.li`
  margin-bottom: 1rem;

  span {
    font-size: ${rem(16)}rem;
  }

      ${breakpoint.gt('md')`
  text-align: right;
  `}
`

export const StyledFlex = styled(Flex)`
  padding-top: 3rem;
    border-top: 1px solid #fff;

`


export const StyledFooterInner = styled(LargeContainerBox)`
    margin: 0;

  ${breakpoint.gt('md')`
      margin: 0 auto;
  `}


      z-index: 3;
      position: relative;
      color: white;

      h1, h2, h3, h4, h5, h6, a{
        color: white;
      }

      a {
        :hover {
            text-decoration: underline;
        }
      }

      #mc_embed_signup {
        background: transparent;
      }
`
