import React, { Suspense } from 'react'

import { Header } from '@components/header'
import { PageHelmet } from '@components/page-helmet'

import { StyledMain } from './style'
import { Footer } from '@components/footer'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

type LayoutProps = {
  children: any
  header: any
  activeMeta: any
  seo: any
  theme?: any
  footer?: any
  hideHeader?: boolean
}

// class Contrast {
//   constructor(imgEl) {}
//   canvas = document.createElement("canvas");
//   context = this.canvas.getContext && this.canvas.getContext('2d');

//   getColor {

//     this.context.drawImage(this.imgEl, this.contentElBox.left * revScale, this.contentElBox.top * revScale, this.contentElBox.width * revScale, this.contentElBox.height * revScale, 0, 0, this.contentElBox.width, this.contentElBox.height);
//   }

// }

const initialOptions = {
  'client-id':
    'AeYox8GQ2ltz_5YVdasU327rfDCsfnsDrJ00eJub_RJHEDZNcxtF4jBFlSzL1Opa7sCCpjIl6GK4oWEi',
  currency: 'EUR',
  intent: 'capture',
  'disable-funding': 'card',
}

export const Layout: React.FC<LayoutProps> = props => {
  const { activeMeta, seo, header, children, footer } = props
  const isSSR = typeof window === 'undefined'
  return (
    <>
      {seo && (
        <PageHelmet
          title={seo?.data?.meta_title?.text}
          description={seo?.data?.meta_description?.text}
          socialImage={seo?.data?.open_graph_image?.url}
          lang={activeMeta?.lang}
          pageUrl={activeMeta?.url}
        />
      )}

      {!isSSR && (
        <Suspense fallback={null}>
          <Header
            props={{
              menu: header,
              activeMeta,
            }}
          />

          {activeMeta?.uid === 'meettheartist' ||
          activeMeta?.type === 'news_post' ? (
            <PayPalScriptProvider options={initialOptions}>
              <StyledMain className={activeMeta?.uid ? activeMeta.uid : ''}>
                {children}
              </StyledMain>
            </PayPalScriptProvider>
          ) : (
            <StyledMain className={activeMeta?.uid ? activeMeta.uid : ''}>
              {children}
            </StyledMain>
          )}
          <Footer props={footer} />
        </Suspense>
      )}
      {/*
      {hideHeader ? (
        <></>
      ) : (
        <Header
          props={{
            menu: header,
            activeMeta,
          }}
        />
      )}
      <StyledMain>{children}</StyledMain>
      <Footer />
      */}
    </>
  )
}
