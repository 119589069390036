import styled from '@emotion/styled'

export const StyledMain = styled.main`
  section:not(.is-first-section) {
    margin-bottom: 8rem;
  }

  section.has_undeline {
    margin-bottom: 4rem;
  }

  > section.is-first-section {
    margin-bottom: 4visrem;
  }
  section.is-first-section {
    margin-top: 10rem;
  }

  section.is-first-section.inside-section {
    margin-top: 4rem;
  }

  section.is-first-section + section:not(.content-with-image) {
    margin-bottom: 0;
  }


`
