import { Flex, Box } from '@rebass/grid/emotion'
import styled from '@emotion/styled'

import {
  oWrapperOuter,
  oWrapperInner,
  oLargeWrapperInner,
  oSmallWrapperInner,
} from '@styles/05-objects/objects.wrapper'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'

export const StyledContainerBox = styled(Box)`
  ${oWrapperOuter}
`
export const StyledContainerBoxInner = styled(Box)`
  ${oWrapperInner}
`

export const StyledLightContainerBox = styled(Box)`
  ${oWrapperInner}
`

export const StyledSmallContainerFlexInner = styled(Flex)`
  ${oSmallWrapperInner}
`

export const StyledLargeContainerBox = styled(Box)`
  ${oWrapperOuter}
  ${oLargeWrapperInner}
`
export const StyledContainerFlexInner = styled(Flex)`
  ${oWrapperInner}

  ${breakpoint.lt('sm')`
    display: block;
  `}
`

export const StyledLargeContainerFlex = styled(Flex)`
  ${oLargeWrapperInner}
`
export const StyledFullwidthContainerFlex = styled(Flex)`
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
`
